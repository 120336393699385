import React from "react";
import { useState } from "react";

export function Submit_Page() {



    const submitData = () => {

        var content = Object.keys(localStorage)
        var contentToSubmit: Array<any> = [];
        content.forEach((k: any) => {
            if (k !== "company-name" && k !== "shopify-url") {
                var tempK = localStorage.getItem(k)
                if (tempK !== null) {
                    contentToSubmit.push(JSON.parse(tempK))
                }
            }
        }
        );
        if (localStorage.getItem('company-name') !== "" && localStorage.getItem('shopify-url') !== "" && JSON.stringify(contentToSubmit) !== "[]") {
            const formBody = new URLSearchParams({
                'company': localStorage.getItem('company-name') || "",
                'shopifyURL': localStorage.getItem('shopify-url') || "",
                'content': JSON.stringify(contentToSubmit) || ""
            })
            fetch("https://dev-api.nostra.ai/company_queue/",
                {
                    "method": 'PUT',
                    "body": formBody,
                    "headers": { 'x-api-key': '8OIjtrzO4o6rr4vglTZAx1hAbOJGzzQX5OMuwtMj' }
                }).then(response => {
                    console.log("SENT")
                    content.forEach((k) => localStorage.removeItem(k))
                })
            return <h2>Submitted data.</h2>
        }
        return <h2>No data to send. <a href="/">Click here</a> to go to home screen and start inputting data.</h2>
    }

    return (
        <React.Fragment>
            <h2>This is the end of your onboarding process, if you have any further questions please email arthur@nostra.ai</h2>
            {submitData()}
        </React.Fragment>
    );
}
