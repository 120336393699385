import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/Home";
import { Review_Edit_Page_v2 } from "./pages/Review_Edit_v2";
import { Submit_Page } from "./pages/Submit"
import './App.css';


function App() {
  return (
    <div className="App-header">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/add" element={<Review_Edit_Page_v2 />} />
          <Route path="/submit" element={<Submit_Page />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App