import parse from 'html-react-parser'
import { useState } from "react";
import { Link } from 'react-router-dom';
var isLoaded: boolean = false;
var showReview_Delete: boolean = false;
var showLoadData: boolean = false;

export function Review_Edit_Page_v2() {
    const [input, setInput] = useState({ uri: "", content: "", variations: [{ variation: '' }] })
    const [inputFields, setInputFields] = useState([{ variation: '' }])

    const [storedC, setContent] = useState({ c: "" })
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    const handleChange2 = (i: number, e: React.ChangeEvent<HTMLInputElement>) => {
        let formValues = [...inputFields]
        formValues[i] = { variation: e.target.value }
        setInputFields(formValues);
    }

    const deleteContentPiece = (e: any) => {
        e.preventDefault();

        localStorage.removeItem((document.getElementById("uris") as HTMLSelectElement)?.value + "-" + (document.getElementById("contentP") as HTMLSelectElement)?.value)

        setInput({
            uri: "",
            content: "",
            variations: [{ variation: '' }]
        })
        setInputFields([{ variation: '' }])
        isLoaded = false;
        if (Object.keys(localStorage).length === 2) showReview_Delete = false;
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!input.content && !input.uri) return
        else if (input.content !== (document.getElementById("contentP") as HTMLSelectElement)?.value || input.uri !== (document.getElementById("uris") as HTMLSelectElement)?.value) {
            localStorage.removeItem((document.getElementById("uris") as HTMLSelectElement)?.value + "-" + (document.getElementById("contentP") as HTMLSelectElement)?.value)
        }
        var newInput = input;
        newInput["variations"] = inputFields;
        localStorage.setItem(newInput.uri + "-" + newInput.content, JSON.stringify(newInput));
        setInput({
            uri: "",
            content: "",
            variations: [{ variation: '' }]
        })
        setInputFields([{ variation: '' }])
        isLoaded = false;
    }

    const addFormFields = () => {
        setInputFields([...inputFields, { variation: '' }])
    }

    const removeFormFields = (i: any) => {
        let formValues = [...inputFields];
        formValues.splice(i, 1);
        setInputFields(formValues)
    }

    const getContent = () => {

        var content = Object.keys(localStorage)
        var uriAndContentName: { [uri: string]: [content: [string]]; } = {};
        content.forEach((k: any) => {
            if (k !== "company-name" && k !== "shopify-url") {
                var tempK = localStorage.getItem(k)
                if (tempK !== null) {
                    var temp = JSON.parse(tempK)
                    if (uriAndContentName[temp.uri] === undefined) {
                        uriAndContentName[temp.uri] = [temp.content]
                    } else {
                        uriAndContentName[temp.uri].push(temp.content)
                    }
                }
            }
        }
        );
        if (content.length > 2) showReview_Delete = true;


        const getC = () => {
            var storedConted = localStorage.getItem((document.getElementById("uris") as HTMLSelectElement)?.value + "-" + (document.getElementById("contentP") as HTMLSelectElement)?.value)

            if (storedConted !== null) {
                var currSelected = JSON.parse(storedConted)

                isLoaded = true;
                setInput({
                    uri: currSelected.uri,
                    content: currSelected.content,
                    variations: [{ variation: '' }]
                })
                setInputFields(currSelected.variations)
                showLoadData = false
            }
        }

        const getPC = () => {
            var html = `<select id='contentP' defaultValue={'DEFAULT'}>`
            html += `<option value=\"\" value="DEFAULT" disabled>Select a content</option>`
            var cp = storedC.c
            if (cp !== "") {
                var parsedVal = JSON.parse(cp);
                for (var k in parsedVal) {
                    html += `<option data-value=\"${parsedVal[k]}\">${parsedVal[k]}</option>`
                }
            }
            html += "</select>"

            return html
        }

        const updatePC = () => {
            setContent({ c: JSON.stringify(uriAndContentName[(document.getElementById("uris") as HTMLSelectElement)?.value]) })
            showLoadData = true
        }

        const getP = () => {
            var html = `<select id='uris' defaultValue={'DEFAULT'}>`
            html += `<option value=\"\" value="DEFAULT" disabled>Select a page</option>`
            for (var i in uriAndContentName) {
                html += `<option data-value=\"${i}\">${i}</option>`
            }
            html += "</select>"
            return html
        }

        return <div>
            {showReview_Delete === true ? <div> <span style={{ fontSize: 22 }}>Select a page:</span> {parse(getP())}  <button type="button" onClick={updatePC}>Load content</button> </div> : null}

            {showReview_Delete === true ? <div style={{ display: showLoadData ? 'block' : 'none' }}><span style={{ fontSize: 22 }}>Select content:</span> {parse(getPC())} <button type="button" onClick={getC}>Load data</button></div> : null}
        </div>
    }
    const vid = '<div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/34a12b9e783d43fcac90c59aa9df08e4" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>'

    return <div>
        <div dangerouslySetInnerHTML={{ __html: vid }}></div>

        <div>
            {getContent()}
        </div>
        <div>
            <div className="InputList">
                <form onSubmit={handleSubmit}>
                    <input type="text" onChange={handleChange} className="InputList-input" name="uri" value={input.uri} placeholder="Page" />
                    <input type="text" onChange={handleChange} className="InputList-input" name="content" value={input.content} placeholder="Content Title" />
                    {inputFields.map((element, index) => (
                        <div className="form-inline" key={index}>
                            {index === 0 ? <input type="text" name="name" value={element.variation || ""} onChange={e => handleChange2(index, e)} placeholder="Current Content Variation" /> :
                                <input type="text" name="name" value={element.variation || ""} onChange={e => handleChange2(index, e)} placeholder="Variation" />
                            }
                            {index ? <button type="button" className="button remove" onClick={() => removeFormFields(index)}>Remove</button> : null}
                        </div>
                    ))}
                    <div className="button-section">
                        <button className="button add" type="button" onClick={() => addFormFields()}>Add Variation</button>
                        <button className="button submit" type="submit">Submit Current Content Piece</button>
                        {showReview_Delete === true && isLoaded ? <button className="button add" type="button" onClick={deleteContentPiece}>Delete Content Piece</button> : null}
                        <h6>Warning: This data will be put into production and you can not edit after this screen.</h6>
                    </div>
                </form>
                <Link to="/submit">
                    <button type="button" className="finalize"> Finalize Onboarding </button>
                </Link>
            </div>
        </div>
    </div>
}
