import React, { useState } from "react";

export function HomePage() {

    const [input, setInput] = useState({ name: "", shopify: "" })

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!input.name && !input.shopify) return
        localStorage.setItem("company-name", input.name)
        localStorage.setItem("shopify-url", input.shopify)
        setInput({
            name: "",
            shopify: "",
        })
        window.location.href = "/add"
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    const vid = '<div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/34a12b9e783d43fcac90c59aa9df08e4" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>'

    return (
        <React.Fragment>
            <h1> Welcome to the Nostra Content Upload Flow </h1>
            <div className="welcome_text">
                <p > We suggest between 1 and 4 elements on a page to optimize. Nostra supports images, videos, text and colors.
                    <br />If you run into any issues feel free to email arthur@nostra.ai:
                </p>
                <p> We will upload content page by page in 3 easy steps <br />
                    1: Enter URL of page you want optimized<br />
                    2: Enter content element you want to optimize <br />
                    3: Enter variations
                </p>
                <p>Here is an easy-to-follow onboarding demonstration.</p>
                <div dangerouslySetInnerHTML={{ __html: vid }}></div>
                <p>Before we get started please input your Company name, as well as your Shopify URL.</p>
                <form onSubmit={handleSubmit}>
                    <input onChange={handleChange} type="text" className="InputList-input" name='name' placeholder="Company Name" required={false} />
                    <br />
                    <input onChange={handleChange} type="text" className="InputList-input" name='shopify' placeholder="Shopify URL" required={false} />
                    <br />
                    <button className="button submit" type="submit">Submit Name/Url</button>
                </form>
            </div>
        </React.Fragment>
    );
}
